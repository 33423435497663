<template>
<div>
   <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
        <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div class="iq-navbar-logo d-flex align-items-center justify-content-between">
            <i class="ri-menu-line wrapper-menu"></i>
            <router-link :to="{name: 'product.listproduct'}" class="header-logo">
                <img :src="logo" class="img-fluid rounded-normal" alt="logo">
            </router-link>
            <h5 class="logo-title ml-3">COUPLUS</h5>
        </div>
            <div class="iq-search-bar device-search mb-0 mt-3 d-flex justify-content-start">
                <b-button variant="outline-warning" class="mb-1 mr-2 d-none d-md-block" href="https://sponge-sound-7be.notion.site/COUPLUS-4e097bd8dd084166aed6438fdacc1c89" target="_blank">
                    쿠플러스 사용자 메뉴얼
                </b-button>
                <b-button variant="outline-success" class="mb-1 mr-2 d-none d-md-block" href="https://chrome.google.com/webstore/detail/couplus/fdfjeigdbhekohnmjbggdcjomicdcjbp?hl=ko&authuser=0" target="_blank">
                    확장 프로그램 다운로드
                </b-button>
            </div>
            <div class="d-flex justify-content-end align-items-center d-none d-md-block">
<!--                <b-navbar-toggle target="nav-collapse" class="navbar-toggler d-none d-md-block">-->
<!--                <i class="ri-menu-3-line d-none d-md-block"></i>-->
<!--                </b-navbar-toggle>-->
                <b-collapse id="nav-collapse" is-nav>
                    <ul class="navbar-nav ml-auto navbar-list align-items-center">
                        <!-- <li class="nav-item nav-icon dropdown" v-nav-toggle>
                            <a href="#" class="search-toggle dropdown-toggle btn border add-btn"
                                id="dropdownMenuButton02" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <img src="@/assets/images/small/flag-01.png" alt="img-flag"
                                    class="img-fluid image-flag mr-2">En
                            </a>
                            <div class="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                <div class="card shadow-none m-0">
                                    <div class="card-body p-3">
                                        <a class="iq-sub-card" href="#"><img
                                                src="@/assets/images/small/flag-02.png" alt="img-flag"
                                                class="img-fluid mr-2">French</a>
                                        <a class="iq-sub-card" href="#"><img
                                                src="@/assets/images/small/flag-03.png" alt="img-flag"
                                                class="img-fluid mr-2">Spanish</a>
                                        <a class="iq-sub-card" href="#"><img
                                                src="@/assets/images/small/flag-04.png" alt="img-flag"
                                                class="img-fluid mr-2">Italian</a>
                                        <a class="iq-sub-card" href="#"><img
                                                src="@/assets/images/small/flag-05.png" alt="img-flag"
                                                class="img-fluid mr-2">German</a>
                                        <a class="iq-sub-card" href="#"><img
                                                src="@/assets/images/small/flag-06.png" alt="img-flag"
                                                class="img-fluid mr-2">Japanese</a>
                                    </div>
                                </div>
                            </div>
                        </li> -->

                        <!-- <li>
                            <a href="#" class="btn border add-btn shadow-none mx-2 d-none d-md-block"
                                v-b-modal.new-order data-target="#new-order"><i class="las la-plus mr-2"></i>New
                                Order</a>
                        </li> -->
                        <!-- <li class="nav-item nav-icon search-content" v-nav-toggle>
                            <a href="#" class="search-toggle rounded dropdown-toggle" id="dropdownSearch" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="ri-search-line"></i>
                            </a>
                            <div class="iq-search-bar iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownSearch">
                                <form action="#" class="searchbox p-2">
                                    <div class="form-group mb-0 position-relative">
                                        <input type="text" class="text search-input font-size-12"
                                            placeholder="type here to search...">
                                        <a href="#" class="search-link"><i class="las la-search"></i></a>
                                    </div>
                                </form>
                            </div>
                        </li> -->
                        <!-- <li class="nav-item nav-icon dropdown" v-nav-toggle>
                            <a href="#" class="search-toggle dropdown-toggle" id="dropdownMenuButton2"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-mail">
                                    <path
                                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                    </path>
                                    <polyline points="22,6 12,13 2,6"></polyline>
                                </svg>
                                <span class="bg-primary"></span>
                            </a>
                            <div class="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                <div class="card shadow-none m-0">
                                    <div class="card-body p-0 ">
                                        <div class="cust-title p-3">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h5 class="mb-0">All Messages</h5>
                                                <a class="badge badge-primary badge-card" href="#">3</a>
                                            </div>
                                        </div>
                                        <div class="px-3 pt-0 pb-0 sub-card">
                                            <a href="#" class="iq-sub-card">
                                                <div class="media align-items-center cust-card py-3 border-bottom">
                                                    <div class="">
                                                        <img class="avatar-50 rounded-small"
                                                            src="@/assets/images/user/01.jpg" alt="01">
                                                    </div>
                                                    <div class="media-body ml-3">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <h6 class="mb-0">Emma Watson</h6>
                                                            <small class="text-dark"><b>12 : 47 pm</b></small>
                                                        </div>
                                                        <small class="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" class="iq-sub-card">
                                                <div class="media align-items-center cust-card py-3 border-bottom">
                                                    <div class="">
                                                        <img class="avatar-50 rounded-small"
                                                            src="@/assets/images/user/02.jpg" alt="02">
                                                    </div>
                                                    <div class="media-body ml-3">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <h6 class="mb-0">Ashlynn Franci</h6>
                                                            <small class="text-dark"><b>11 : 30 pm</b></small>
                                                        </div>
                                                        <small class="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" class="iq-sub-card">
                                                <div class="media align-items-center cust-card py-3">
                                                    <div class="">
                                                        <img class="avatar-50 rounded-small"
                                                            src="@/assets/images/user/03.jpg" alt="03">
                                                    </div>
                                                    <div class="media-body ml-3">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <h6 class="mb-0">Kianna Carder</h6>
                                                            <small class="text-dark"><b>11 : 21 pm</b></small>
                                                        </div>
                                                        <small class="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <a class="right-ic btn btn-primary btn-block position-relative p-2" href="#"
                                            role="button">
                                            View All
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li> -->
                        <!-- <li class="nav-item nav-icon dropdown" v-nav-toggle>
                            <a href="#" class="search-toggle dropdown-toggle" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-bell">
                                    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                    <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                </svg>
                                <span class="bg-primary "></span>
                            </a>
                            <div class="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div class="card shadow-none m-0">
                                    <div class="card-body p-0 ">
                                        <div class="cust-title p-3">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h5 class="mb-0">Notifications</h5>
                                                <a class="badge badge-primary badge-card" href="#">3</a>
                                            </div>
                                        </div>
                                        <div class="px-3 pt-0 pb-0 sub-card">
                                            <a href="#" class="iq-sub-card">
                                                <div class="media align-items-center cust-card py-3 border-bottom">
                                                    <div class="">
                                                        <img class="avatar-50 rounded-small"
                                                            src="@/assets/images/user/01.jpg" alt="01">
                                                    </div>
                                                    <div class="media-body ml-3">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <h6 class="mb-0">Emma Watson</h6>
                                                            <small class="text-dark"><b>12 : 47 pm</b></small>
                                                        </div>
                                                        <small class="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" class="iq-sub-card">
                                                <div class="media align-items-center cust-card py-3 border-bottom">
                                                    <div class="">
                                                        <img class="avatar-50 rounded-small"
                                                            src="@/assets/images/user/02.jpg" alt="02">
                                                    </div>
                                                    <div class="media-body ml-3">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <h6 class="mb-0">Ashlynn Franci</h6>
                                                            <small class="text-dark"><b>11 : 30 pm</b></small>
                                                        </div>
                                                        <small class="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" class="iq-sub-card">
                                                <div class="media align-items-center cust-card py-3">
                                                    <div class="">
                                                        <img class="avatar-50 rounded-small"
                                                            src="@/assets/images/user/03.jpg" alt="03">
                                                    </div>
                                                    <div class="media-body ml-3">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <h6 class="mb-0">Kianna Carder</h6>
                                                            <small class="text-dark"><b>11 : 21 pm</b></small>
                                                        </div>
                                                        <small class="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <a class="right-ic btn btn-primary btn-block position-relative p-2" href="#"
                                            role="button">
                                            View All
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li> -->
                        <li>
                            <a class="mr-0" href="http://pf.kakao.com/_xhDjxeb" target="_blank"><img src="@/assets/images/small/ch.png" class="img-fluid rounded" alt="user"></a>
                        </li>
                        <li class="nav-item nav-icon dropdown caption-content" v-nav-toggle>
                            <a href="#" class="search-toggle dropdown-toggle" id="dropdownMenuButton4"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <!-- <img src="@/assets/images/user/1.png" class="img-fluid rounded" alt="user"> -->

                                <!-- <p class="rounded-top img-fluid mb-1 ml-0">Company ID : Test</p> -->
                                <!-- <p class="rounded-top img-fluid mb-0 ml-0" v-if="showSellerInfo">Company ID : {{companyid}} &nbsp;</p> -->
                                <b-button variant="outline-primary" class="change-button">계정관리</b-button>
                            </a>
                            <div class="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div class="card shadow-none m-0">
                                    <div class="card-body p-0 text-center">
                                        <div class="media-body profile-detail text-center">
                                            <img src="@/assets/images/page-img/profile-bg.jpg" alt="profile-bg"
                                                class="rounded-top img-fluid mb-4">
                                        </div>
                                        <div class="p-3">
                                            <p class="mb-0" v-if="showSellerInfo">{{companyid}} / {{suppliercode}}</p>
                                            <h5 class="mb-1">{{email}}</h5>
                                            <p class="mb-0">{{accesshistory}}</p>
                                            <div class="d-flex align-items-center justify-content-center mt-3">
                                                <p class="btn border-secondary" style="height:200%;" @click="logout">로그아웃</p>
                                                <!-- <p><router-link :to="{name: 'people.suppliers'}" class="btn border-primary mr-2">예치금<br>10,000,000원</router-link></p> -->
                                                <!-- <p v-b-modal.modal-3 class="btn border-primary ml-2" style="height:200%;">설정</p>

                                                    <b-modal id="modal-3" size="lg" title="설정" ok-title="저장" cancel-title="닫기" @ok="savesetting">
                                                        <div class="card auth-card">
                                                            <div class="card-body p-0">
                                                                <div class="d-flex align-items-center auth-content">
                                                                <div class="col-lg-12 align-self-center">
                                                                    <div class="p-3">
                                                                        <h2 class="mb-2">자동수집설정</h2>
                                                                        <p>판매자코드({{suppliercode}})의 Supplier(or Wing) 아이디/비번을 설정하는 경우<br>
                                                                        매일 00시부터 순차적으로 SKU리스트/판매현황이 자동수집됩니다.<br>
                                                                        자동수집을 위해 2차 인증이 필요할 수 있으며, 모든 데이터는 암호화되어 저장됩니다.</p>

                                                                        <div class="mt-2 d-flex">
                                                                            <div class="form-group">
                                                                                <input type="text" class="form-control" placeholder="쿠팡아이디" v-model="id" @click="deleteid">
                                                                            </div>
                                                                            <div class="form-group ml-2">
                                                                                <input type="password" class="form-control" placeholder="쿠팡비밀번호" v-model="pw" @click="deletepw">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-modal> -->

                                                <p v-b-modal.editpayment class="btn border-primary ml-2" style="height:200%;">설정</p>

                                                    <b-modal id="editpayment" size="lg" title="설정" ok-only>

                                                            <div class="card auth-card">
                                                                <div class="card-body p-0">
                                                                    <!-- <div class="d-flex align-items-center auth-content" v-if="$store.state.user.auth.showimport">
                                                                        <div class="col-lg-12 align-self-center">
                                                                            <div class="p-3">
                                                                                <h5 class="mb-2">자동수집설정</h5>
                                                                                <p>
                                                                                "Team Member"로 부계정을 추가해 주시고, "couplus01@gmail.com"으로 등록해 주세요.<br>
                                                                                등록후에 채널톡으로 문의해 주시면, 확인 후 아이디 생성 및 자동수집서버에 해당아이디를 세팅해 드립니다.<br>
                                                                                이후에는 매일 00시부터 순차적으로 SKU리스트와 판매현황이 자동수집됩니다.<br>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div> -->
                                                                    <div class="d-flex justify-content-start p-3">
                                                                        <div v-if="showpayment" class="p-3">
                                                                            <h5>현재 구독 결제 기준일 : 매월 {{ now }}일</h5>
                                                                            <p>{{ paymentcontent }}</p>
                                                                            <b-button  :variant="btnclass" class="mb-2 mt-2" @click="changepaymentstatus">{{ paymenttext }}</b-button>

                                                                        </div>
                                                                        <div v-else class="p-3">
                                                                            <h5>결제일(만료일) : {{ expireDate }}</h5>
                                                                            <p>현재 구독 설정이 되어 있지 않습니다. 다음 결제일부터 구독결제를 진행해 주세요</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        <div class="card auth-card" v-b-modal.changePassword>
                                                            <div class="card-body p-0">
                                                                <div class="d-flex justify-content-start p-3">
                                                                    <div class="p-3">
                                                                        <h5>비밀번호 변경 ></h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card auth-card" v-b-modal.geminiApi v-if="$store.state.user.auth.shownomad">
                                                            <div class="card-body p-0">
                                                                <div class="d-flex justify-content-start p-3">
                                                                    <div class="p-3">
                                                                        <h5>Gemini API 설정 ></h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card auth-card" v-b-modal.datalabAPI v-if="$store.state.user.auth.shownomad">
                                                            <div class="card-body p-0">
                                                                <div class="d-flex justify-content-start p-3">
                                                                    <div class="p-3">
                                                                        <h5>네이버 데이터랩 API 설정 ></h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-modal>
                                            </div>
                                            <b-modal id="changePassword" title="비밀번호 변경" hide-footer size="sm">
                                                <b-form class="position-relative">
                                                    <b-form-group class="mb-0" label-for="newPassword">
                                                        <b-form-input class="pw-input" placeholder="현재 비밀번호 입력" type="password" v-model="currentPassword" />
                                                        <b-form-input class="pw-input" placeholder="새로운 비밀번호 입력" type="password" v-model="newPassword" />
                                                        <b-form-input class="pw-input" placeholder="새로운 비밀번호 확인" type="password" v-model="checkNewPassword" />
                                                        <div class="change-button-area">
                                                            <b-button variant="outline-primary" class="change-button" @click="onClickPasswordChange">
                                                                변경
                                                            </b-button>
                                                        </div>
                                                    </b-form-group>
                                                </b-form>
                                            </b-modal>
                                            <b-modal id="geminiApi" title="Gemini API 설정" hide-footer size="sm">
                                                <b-form class="position-relative">
                                                    <b-form-group class="mb-0" label-for="gemini">
                                                        <h6><a class="text-primary" href="https://aistudio.google.com/app/apikey" target="_blank">Gemini API</a> Key를 입력해 주세요. </h6>
                                                        <b-form-input class="pw-input mt-2" placeholder="Gemini API Key 입력" type="text" v-model="geminiAPIKey" />
                                                        <b-button variant="outline-primary" class="change-button" @click="onClickGeminiAPIChange">적용</b-button>
                                                    </b-form-group>
                                                </b-form>
                                            </b-modal>
                                            <b-modal id="datalabAPI" size="xl" scrollable title="네이버 데이터랩 API설정" ok-only ok-title="닫기" class="detail-modal">
                                                <div>
                                                    <p><a href="https://developers.naver.com/apps/#/register=datalab" target="_blank"><u>네이버개발자센터</u></a>에 접속해서 아래의 순서로 API key를 받은 후 입력하고 API저장을 눌러주세요</p>
                                                    <card class="w-50 p-3">
                                                        <div class="d-flex justify-content-start align-items-center w-100">
                                                            <div class="w-25">Client ID : </div>
                                                            <div class="w-75">
                                                                <input type="text" class="form-control text-center" placeholder="Client ID" v-model="clientId">
                                                            </div>
                                                        </div>
                                                        <div class="d-flex justify-content-start align-items-center w-100">
                                                            <div class="w-25">Client Secret : </div>
                                                            <div class="w-75">
                                                                <input type="text" class="form-control text-center" placeholder="Client Secret" v-model="clientSecret">
                                                            </div>
                                                        </div>
                                                        <b-button class="w-25 mt-3" variant="outline-success" @click="saveAPI">API저장</b-button>
                                                    </card>
                                                </div>
                                                <img  style="max-width:100%;" :src="require('../../../../assets/images/small/데이터랩api.png')">
                                            </b-modal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </b-collapse>
            </div>
        </nav>
    </div>
</div>
<!-- <b-modal id="new-order" centered hide-footer hide-header hide-header-close>
    <div class="popup text-left">
        <h4 class="mb-3">New Order</h4>
        <div class="content create-workform bg-body">
            <div class="pb-3">
                <label class="mb-2">{{email}}</label>
                <input type="text" class="form-control" placeholder="Enter Name or Email">
            </div>
            <div class="col-lg-12 mt-4">
                <div class="d-flex flex-wrap align-items-ceter justify-content-center">
                    <div class="btn btn-primary mr-4" data-dismiss="modal" @click="$bvModal.hide('new-order')">Cancel</div>
                    <div class="btn btn-outline-primary" data-dismiss="modal" @click="$bvModal.hide('new-order')">Create</div>
                </div>
            </div>
        </div>
    </div>
</b-modal> -->
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
    name:"HeaderStyle1",
    computed : {
        ...mapGetters({
            companyid: 'companyid',
            suppliercode: 'suppliercode',
            appName: 'appName',
            logo:'logo',
            accesshistory: 'accesshistory',
            email:'email',
            image1:'image1',
            expireDate: 'expireDate'
        })
    },
    data(){
		return{
            showSellerInfo:true,
			id:'쿠팡아이디',
            pw:'쿠팡비번',
            now:'',
            paymenttext:'',
            paymentcontent:'',
            btnclass:'',
            showpayment:true,
            currentPassword: '',
            newPassword: '',
            checkNewPassword: '',
            geminiAPIKey:'',
            clientId: this.$store.state.settings[0].clientId ? this.$store.state.settings[0].clientId : '',
			clientSecret: this.$store.state.settings[0].clientSecret ? this.$store.state.settings[0].clientSecret : '',
		}
	},
    mounted(){
        this.setpaymenttext();
        this.setShowSellerInfo();
        this.setGeminiAPIKey();
    },
    methods: {
        async saveAPI(){
			try{
				var result = await axios.post('/api/setDatalabApi',{clientId:this.clientId,clientSecret:this.clientSecret});
				if(result.data.요청결과 == 'success'){
					var obj = this.deepClone(this.$store.state.settings[0]);
					obj.clientId = this.clientId;
					obj.clientSecret = this.clientSecret;
					this.$store.commit('settingsupdate',[obj])
					alert('저장완료')
				} else {
					alert('저장실패(510)');
					console.log(result.data.result);
				}
			}catch(e){
				alert('저장실패(514)')
				console.log(e)
			}
		},
        deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
        setGeminiAPIKey(){
            console.log(this.$store.state.settings[0])
            if(this.$store.state.user.auth.showAI && this.$store.state.settings[0].geminiAPIKey){
                this.geminiAPIKey = this.$store.state.settings[0].geminiAPIKey;
            }
        },
        setShowSellerInfo(){
            if(this.$store.state.user.auth.showSellerInfo === false){
                this.showSellerInfo = false;
            }
        },
        setpaymenttext(){
            this.now = this.$store.state.user.paydate;
            if(this.now){
                this.showpayment = true;
            } else {
                this.showpayment = false;
            }
            if(this.$store.state.user.nextpayment){
                this.paymenttext = '구독취소';
                this.paymentcontent = '구독취소 시 다음달 결제일 부터 구독결제가 종료됩니다.'
            } else {
                this.paymenttext = '구독시작';
                this.paymentcontent = '구독시작 시 다음달 결제일 부터 구독결제가 시작됩니다.'
            }
            this.changebtnclass();
        },
        changebtnclass(){
            if(this.paymenttext == '구독취소'){
                this.btnclass = 'outline-secondary';
            } else {
                this.btnclass = 'outline-success';
            }
        },
        changepaymentstatus(){
            if(this.paymenttext == '구독취소'){
                if(!confirm('구독취소 시 해당 아이디의 기존권한 및 모든 데이터가 삭제됩니다. 계속하시겠습니까?')){
                    return
                }
            }
            axios.post('/api/login/changepaymentstatus',{nextpayment : this.$store.state.user.nextpayment})
            .then((result)=>{
                if(result.data.요청결과 == 'success'){
                    alert(result.data.message);
                    this.$store.commit('userupdate',result.data.userinfo);
                    this.setpaymenttext();
                } else {
                    alert(result.data.message);
                }
            })
            .catch((e)=>{
                console.log(e)
            })
        },
        deleteid(){this.id = ''},
        deletepw(){this.pw = ''},
        logout() {
            axios.post('/api/logout')
            .then((result) =>{
                console.log(result)
                console.log(result.data)
                console.log(result.data.loginstatus)
                if (result.data.loginstatus == false) {
                    this.$store.commit('resetState');
                    this.$router.push({name: 'auth.login'})
                }
            })
            .catch(console.log)
        },
        savesetting(){
            axios.post('/api/savesetting',{coupangid:this.id,coupangpw:this.pw})
            .then((result) =>{
                console.log(result)
                if (result.data.요청결과 == '저장완료') {
                    alert('서버의 쿠팡 접속을 위해 수일 이내에 2차 인증 요청으로 연락 드릴 예정입니다.');
                }
            })
            .catch(console.log)
        },
        onClickPasswordChange() {
          if (this.newPassword !== this.checkNewPassword) {
              alert('새 비밀번호와 비밀번호 확인이 일치하지 않습니다.')
          } else {
              axios.post('/api/auth/change-password', {currentPwd: this.currentPassword, newPwd: this.newPassword, email: this.$store.state.user.email})
                  .then((response) => {
                      if (response.data.result === 'success') {
                          alert('비밀번호가 변경됐습니다.')
                          this.$bvModal.hide('changePassword')
                          this.currentPassword = ''
                          this.newPassword = ''
                          this.checkNewPassword = ''
                      } else {
                          alert(response.data.message)
                          console.log(response.data)
                      }
                  })
                  .catch((error) => {
                      alert(error.response.data)
                  })
          }
        },
        async onClickGeminiAPIChange() {
            if (!this.geminiAPIKey) {
                alert('Gemini API를 입력해 주세요')
                return
            }
            try{
                var res = await axios.post('/api/setGeminiApi', {key: this.geminiAPIKey})
                if (res.data.요청결과 === '저장완료') {
                    alert('Gemini API Key가 적용 되었습니다.')
                    this.$store.commit('settingsupdateparameter',{key:'geminiAPIKey',value:this.geminiAPIKey});
                    console.log(this.$store.state.settings)
                    this.$bvModal.hide('geminiApi');
                } else {
                    alert(res.data.요청결과)
                    console.log(res.data)
                } 
            } catch(error){
                alert(error.response.data)
            }
        },
    }


}
</script>

<style lang="scss">
.pw-input {
    margin-bottom: 25px;
}
.change-button-area {
    display: flex;
    justify-content: center;
    align-items: center;
    .change-button {
        width: 15%;
    }
}
#changePassword {
    max-width: 50%;

    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
